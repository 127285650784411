.box_delete{
    width: 550px;
}
.delete_dialog_content1{
        line-height: 28px;
        font-weight: 700;
        font-size: 20px;
        color:  #1b2430;
        margin-top: 1rem;
        margin-left: 1rem;
}
.delete_dialog_content2 {
        font-weight: 400;
        line-height: 23px;
        font-size: 14px;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        /* height:250px; */
}
.delete_button{
font-weight: 700;
font-size: 14px;
}