.tags-input-container {
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  padding: 0.5em;
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}
.tags-input-container-keywords {
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}
.tags-input-container-error {
  border: 1px solid #d32f2f;
  border-radius: 8px;
  padding: 0.5em;
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c0c0c0;
  opacity: 1; /* Firefox */
}

.tag-item {
  background: #ebebec;
  border-radius: 800px;
  display: inline-block;
  padding: 4px 8px;
}
.tag-item .close {
  height: 9px;
  width: 9px;
  color: #000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;

  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}
