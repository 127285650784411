.box_iframe{
        width: auto;
}
.delete_dialog_content1{
            line-height: 28px;
            font-weight: 700;
            font-size: 20px;
            color:  #1b2430;
            margin-top: 1rem;
            margin-left: 1rem;
}
.delete_dialog_content2 {
            font-weight: 400;
            line-height: 23px;
            font-size: 14px;
            /* margin-top: 24px; */
            margin-left: 1rem;
}
.delete_button{
font-weight: 700;
font-size: 14px;
}