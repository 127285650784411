.postcard_grid{
    display:  flex;
    margin-top: 25px;
    justify-content: space-between;
}

/* All Post */
.Autocomplete-field{
    width: 100%;
    background-color:white ;
    justify-content: space-around;
 /* margin-bottom: 2rem ; */

}
.Paper{
   border: 1px solid #E0E0E0 ;
   border-radius: 8px ;
   padding-inline: 24px;
}
